<style global lang="scss">form {
  max-width: 500px;
  padding: 20px;
  margin: auto; }
  form p {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  form span {
    position: relative; }
  form label {
    width: 100%;
    margin-top: 2px;
    cursor: text; }
  form input,
  form textarea {
    outline: none;
    background: transparent;
    border: none;
    width: 100%;
    margin-top: -2px;
    padding-left: 40px;
    resize: none; }
    form input:-webkit-autofill,
    form textarea:-webkit-autofill {
      box-shadow: 0 0 50px 6px #f5f4f0 inset; }
  form input::-webkit-input-placeholder {
    color: #aaa; }
  form input:focus::-webkit-input-placeholder {
    color: dodgerblue; }
  form .input:focus + .underline {
    transform: scale(1); }
  form .underline {
    background-color: #fcc4bf69;
    display: block;
    height: 2px;
    margin-top: -12px;
    z-index: -2; }
  form .error {
    color: #ca2b3e; }
  @media (max-width: 500px) {
    form input,
    form textarea {
      padding-left: 0.4em; } }

/*# sourceMappingURL=change-date.html.css.map */</style>
<script>
  import { navigate } from "svelte-routing";
  import { _ } from "../services/i18n";
  export let action;
  let errors = { name: false, address: false };
  const validate = e => {
    if (e.target.value) errors[e.target.name] = false;
  };
  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if (!form.name.value) errors.name = true;
    if (!form.address.value) errors.address = true;
    if (Object.values(errors).some(error => error)) return;
    fetch(form.action, { method: "post", body: new FormData(form) });
    navigate(action);
  };
</script>
<form name="newformmail" action="{action}" on:submit="{onSubmit}" netlify>
  <p>
    <label>
      <span>
        {$_('form.name')}
        <span class="error"
          >{errors.name ? ` (${$_('form.required')})` : ''}</span
        >
      </span>
      <input type="text" name="name" on:input="{validate}" />
      <span class="underline" />
    </label>
    <label>
      <span>
        {$_('form.address')}
        <span class="error"
          >{errors.address ? ` (${$_('form.required')})` : ''}</span
        >
      </span>
      <textarea
        type="text"
        name="address"
        rows="7"
        on:input="{validate}"
      ></textarea>
      <span class="underline" />
    </label>
    <input type="hidden" name="form-name" value="newformmail" />
    <button
      type="submit"
      disabled="{Object.values(errors).some(error => error)}"
    >
      {$_('form.send')}
    </button>
  </p>
</form>
