<style global lang="scss">.overnight {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.days {
  padding: 20px; }

.rooms {
  padding-bottom: 64px; }

.roomtypes {
  text-align: left; }

.page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px; }

p.submit {
  max-width: 800px; }

.attending {
  padding: 10px;
  position: relative;
  max-width: 700px; }

.rsvp {
  text-align: center;
  padding-bottom: 64px; }

h2 {
  align-self: center; }

button {
  font-size: 2em; }

form textarea {
  width: 90%;
  resize: vertical; }

@media (max-width: 500px) {
  input,
  textarea {
    padding-left: 0.4em; } }

/*# sourceMappingURL=rsvp.html.css.map */</style>
<script>
  import { navigate } from "svelte-routing";
  import { _ } from "../services/i18n";
  import RSVPSingle from "../routes/RSVPSingle.svelte";
  export let login;
  export let maxAttending;
  export let action;
  let attending = maxAttending;

  let numFridaySingle = 0;
  let numFridayDoubleSmall = 0;
  let numFridayDoubleStandard = 0;
  let numSaturdaySingle = 0;
  let numSaturdayDoubleSmall = 0;
  let numSaturdayDoubleStandard = 0;

  let errors = {
    attendingCount: false
  };

  const updateErrorMapping = e => {
    for (let step = attending + 1; step <= maxAttending; step++) {
      delete errors[step];
      errors = { ...errors };
    }
    errors.attendingCount = attending > maxAttending || attending < 0;
  };

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;

    if (attending > maxAttending || attending < 0) {
      errors.attendingCount = true;
    }

    if (Object.values(errors).some(error => error)) return;
    fetch(form.action, { method: "post", body: new FormData(form) });
    navigate(action);
  };
</script>

<form
  name="rsvp{attending}"
  action="{action}"
  on:submit="{onSubmit}"
  netlify-honeypot="bot-field"
  netlify
>
  <input type="hidden" name="email" bind:value="{login}" />

  <h2>{$_("rsvp.form.attending")}</h2>
  <label>
    {attending}
    <input
      name="numAttending"
      type="range"
      bind:value="{attending}"
      min="0"
      max="{maxAttending}"
      on:input="{updateErrorMapping}"
    />
  </label>
  {#if errors.attendingCount}
  <div>
    <span class="error">{$_("rsvp.form.attending.error")}</span>
  </div>
  {/if}

  <div class="page">
    {#each Array(attending) as user, i}
    <div class="attending" name="single">
      <h3>{$_("rsvp.form.guest.title")} #{i + 1}</h3>
      <RSVPSingle key="{i + 1}" bind:errors />
    </div>
    {/each}
  </div>
  {#if attending == 0}
  <div class="page">
    <p>{$_("rsvp.form.attending.none")}</p>
  </div>
  {:else}
  <div class="rooms">
    <h2>{$_("rsvp.form.rooms")}</h2>
    <div class="overnight">
      <div class="days">
        <h3>{$_("rsvp.form.rooms.friday")}</h3>
        <div class="roomtypes">
          <label>
            <input
              name="numFridaySingle"
              type="number"
              bind:value="{numFridaySingle}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.single")}
          </label>
          <label>
            <input
              name="numFridayDoubleSmall"
              type="number"
              bind:value="{numFridayDoubleSmall}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.twin")}
          </label>
          <label>
            <input
              name="numFridayDoubleStandard"
              type="number"
              bind:value="{numFridayDoubleStandard}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.double")}
          </label>
        </div>
      </div>
      <div class="days">
        <h3>{$_("rsvp.form.rooms.saturday")}</h3>
        <div class="roomtypes">
          <label>
            <input
              name="numSaturdaySingle"
              type="number"
              bind:value="{numSaturdaySingle}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.single")}
          </label>
          <label>
            <input
              name="numSaturdayDoubleSmall"
              type="number"
              bind:value="{numSaturdayDoubleSmall}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.twin")}
          </label>
          <label>
            <input
              name="numSaturdayDoubleStandard"
              type="number"
              bind:value="{numSaturdayDoubleStandard}"
              min="0"
              max="{attending}"
            />
            {$_("rsvp.form.rooms.double")}
          </label>
        </div>
      </div>
    </div>
  </div>
  {/if}

  <div class="page">
    <label>
      <h2>
        {$_("rsvp.form.note")}
      </h2>
      <textarea
        type="text"
        name="note"
        rows="4"
        placeholder="{$_('rsvp.form.note.placeholder')}"
      ></textarea>
    </label>
  </div>
  
  <div class="page">
    <p class="submit">
      {$_("rsvp.form.submit.info")}
      <a href="mailto:hello@adamlisa.wedding" target="_blank">hello@adamlisa.wedding</a>!
    </p>
  </div>

  <input type="hidden" name="bot-field" />

  <input type="hidden" name="form-name" value="rsvp{attending}" />

  <button type="submit" disabled="{Object.values(errors).some(error => error)}">
    {$_('form.send')}
  </button>
</form>
